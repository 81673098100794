var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wit-checkbox-v2",class:{
        'wit-checkbox-v2': true,
        'wit-checkbox-v2--checked': _vm.localValue,
        'wit-checkbox-v2--disabled': _vm.disabled,
        'wit-checkbox-v2--valid': _vm.state === true,
        'wit-checkbox-v2--invalid': _vm.state === false,
    },on:{"click":_vm.emitInput}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}),_c('inline-svg',{attrs:{"src":require('@/assets/img/checkbox-v2.svg')}}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }