<template>
    <div class="app">
        <div class="justify-content-center">
            <b-card no-body class="login-box">
                <h2 class="login-box__header">Sign up</h2>
                <p class="login-box__subheader">Complete your registration</p>

                <b-card-body>
                    <wit-tabless-form
                        :formId="formId"
                        :inputs="inputs"
                        ref="tablessForm"
                        :valid="!$v.$invalid"
                        finish-label="Sign up"
                        @input="onFormInput"
                        @finish="register"
                    >
                        <template #input-checkmarks="{step}">
                            <WitCheckboxV2
                                style="margin-left: 5px"
                                v-model="$v.registerForm.termsChecked.$model"
                                :state="validateRef('registerForm.termsChecked', step)"
                            >
                                I have read and agree to the
                                <a target="_blank" href="https://witbee.com/legal/witcloud-terms-of-use "
                                    >Terms and conditions</a
                                >
                            </WitCheckboxV2>

                            <Feedback
                                :state="validateRef('registerForm.termsChecked', step)"
                                invalid="This field is required"
                            ></Feedback>

                            <WitCheckboxV2
                                style="margin-left: 5px"
                                v-model="$v.registerForm.policyChecked.$model"
                                :state="validateRef('registerForm.policyChecked', step)"
                            >
                                I have read and agree to the
                                <a target="_blank" href="https://witbee.com/legal/witcloud-privacy-policy"
                                    >Privacy Policy</a
                                >
                            </WitCheckboxV2>

                            <Feedback
                                :state="validateRef('registerForm.policyChecked', step)"
                                invalid="This field is required"
                            ></Feedback>

                            <b-form-group>
                                <vue-recaptcha
                                    :sitekey="recaptchaSiteKey"
                                    :loadRecaptchaScript="true"
                                    @verify="onRecaptchaVerify"
                                    @expired="onRecaptchaExpired"
                                    @error="onRecaptchaError"
                                    ref="recaptcha"
                                    style="transform: scale(0.77); 
-webkit-transform: scale(0.77); transform-origin: 0 0;
-webkit-transform-origin: 0 0;"
                                ></vue-recaptcha>
                            </b-form-group>
                        </template>

                        <template #feedback>
                            <Feedback :state="registerError.state" :invalid="registerError.message"></Feedback>
                        </template>
                    </wit-tabless-form>
                    <p class="login-box__footer">
                        Already have an account? <router-link to="/auth/login">Log in</router-link>
                    </p>
                    <p></p
                ></b-card-body>
            </b-card>
        </div>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email, sameAs} from 'vuelidate/lib/validators'
import VueRecaptcha from 'vue-recaptcha'

import Feedback from '@/components/Feedback'
import Loading from '@/components/loading.vue'
import WitCheckboxV2 from '@/components/Inputs/WitCheckboxV2.vue'
import WitTablessForm from '@/components/WitTablessForm.vue'

import {formMixin} from '@/mixins/formMixin'

export default {
    components: {
        Feedback,
        Loading,
        WitTablessForm,
        WitCheckboxV2,
        VueRecaptcha,
    },
    mixins: [formMixin, validationMixin],
    data() {
        return {
            registerError: {
                state: true,
                message: '',
            },
            invitationId: null,
            lockEmail: false,
            formId: 'register-form',
            registerForm: {
                email: '',
                name: '',
                password: '',
                repeatedPassword: '',
                termsChecked: false,
                policyChecked: false,
                recaptchaResponse: '',
            },
            cannotContinue: true,
            step: {
                checked: false,
            },
            briefId: null,
            recaptchaExpired: false,
            recaptchaError: false,
        }
    },
    validations: {
        registerForm: {
            email: {
                required,
                email,
            },
            password: {
                required,
                minLength: value => value && value.length >= 8,
                upperAndLowerCase: value =>
                    value &&
                    Array.from(value).some(char => char === char.toLowerCase()) &&
                    Array.from(value).some(char => char === char.toUpperCase()),
                number: value => /\d/.test(value),
                symbol: value => /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value),
            },
            repeatedPassword: {
                required,
                sameAsPassword: sameAs(function() {
                    return this.registerForm.password
                }),
            },
            termsChecked: {
                required,
                sameAs: sameAs(() => true),
            },
            policyChecked: {
                required,
                sameAs: sameAs(() => true),
            },
            recaptcha: {
                valid: function() {
                    return (
                        Boolean(this.registerForm.recaptchaResponse) && !this.recaptchaExpired && !this.recaptchaError
                    )
                },
            },
        },
    },
    computed: {
        recaptchaSiteKey() {
            return process.env.VUE_APP_RECAPTCHA_SITE_KEY
        },
        validationMessages() {
            return {
                minLength: 'Use 8 or more characters',
                number: 'Use a number',
                upperAndLowerCase: 'Use upper and lower case letters',
                symbol: 'Use a symbol',
            }
        },
        inputs() {
            return [
                {
                    name: 'email',
                    value: this.$v.registerForm.email.$model,
                    model: this.$v.registerForm.email,
                    type: 'text',
                    inputLabel: 'Email',
                    placeholder: 'sample@email.com',
                    invalid: 'This field is required',
                    disabled: this.lockEmail,
                    valid: 'Email is valid',
                },
                {
                    name: 'password',
                    value: this.$v.registerForm.password.$model,
                    model: this.$v.registerForm.password,
                    type: 'password',
                    inputLabel: 'Password',
                    placeholder: 'Type here',
                    invalid: 'This field is required',
                    valid: 'Password is valid',
                    validationMessages: this.validationMessages,
                },
                {
                    name: 'repeated-password',
                    value: this.$v.registerForm.repeatedPassword.$model,
                    model: this.$v.registerForm.repeatedPassword,
                    type: 'password',
                    inputLabel: 'Repeat password',
                    placeholder: 'Type here',
                    invalid: 'This field is required',
                    valid: 'Password is valid',
                },
                {
                    name: 'checkmarks',
                },
            ]
        },
    },
    created() {
        const {invitationId, b_id} = this.$route.query

        if (invitationId) {
            this.invitationId = invitationId
            this.fetchInvitation()
        }

        if (b_id) {
            this.briefId = b_id
            this.fetchBrief()
        }
    },
    methods: {
        async fetchInvitation() {
            try {
                const response = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/invitations/${this.invitationId}`
                )
                this.registerForm.email = response.data.data.emailInvited
                this.lockEmail = true
            } catch {}
        },

        async fetchBrief() {
            try {
                this.$store.commit('loading/PROCESSING', `Preparing the form...`)
                const response = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/briefs/${this.briefId}`)
                this.registerForm.email = response.data.data.email
                this.registerForm.termsChecked = true
                this.registerForm.policyChecked = true
                this.lockEmail = true
            } catch {}
            this.$store.commit('loading/PROCESSED')
        },

        closeForbiddenModal() {
            this.showForbiddenModal = false
        },

        async register() {
            this.step.checked = true
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                try {
                    this.registerError.state = true
                    this.registerError.message = null

                    const data = {
                        email: this.registerForm.email.toLowerCase(),
                        password: this.registerForm.password,
                        recaptchaResponse: this.registerForm.recaptchaResponse,
                    }

                    this.$store.commit('loading/PROCESSING', `Registering...`)
                    const user = await this.$auth.register({
                        data,
                        success(response) {
                            const user = response.data.data

                            this.$gtm.trackEvent({
                                event: 'sign_up',
                                userId: user.id,
                            })

                            localStorage.removeItem('activeProjectId')
                            localStorage.setItem('registeredEmail', user.email)

                            this.$store.commit('loading/PROCESSED')
                        },
                        autoLogin: false,
                        rememberMe: true,
                    })
                } catch (e) {
                    this.$store.commit('loading/PROCESSED')
                    this.registerError.state = false
                    this.registerError.message =
                        e.response.data && e.response.data.data ? e.response.data.data : 'Could not register'
                    this.$errorHandler.report(e, 'Could not register')
                    if (this.$refs.recaptcha) {
                        this.$refs.recaptcha.reset()
                    }
                }
            }
        },

        onRecaptchaVerify(key) {
            this.recaptchaError = false
            this.recaptchaExpired = false
            this.registerForm.recaptchaResponse = key
        },

        onRecaptchaExpired() {
            this.recaptchaError = false
            this.recaptchaExpired = true
            this.registerForm.recaptchaResponse = ''
        },

        onRecaptchaError() {
            this.recaptchaExpired = false
            this.recaptchaError = true
            this.registerForm.recaptchaResponse = ''
        },
    },
}
</script>

<style lang="scss">
.form-control.vue-tel-input {
    border-radius: 5px;
    border: 1px solid #e8e8e8;
}

#register-form {
    .step-form {
        background: none;
        box-shadow: none;
    }
    #register-form-address-line1 {
        margin-bottom: 7px;
    }
}
</style>
