<template>
    <div
        class="wit-checkbox-v2"
        :class="{
            'wit-checkbox-v2': true,
            'wit-checkbox-v2--checked': localValue,
            'wit-checkbox-v2--disabled': disabled,
            'wit-checkbox-v2--valid': state === true,
            'wit-checkbox-v2--invalid': state === false,
        }"
        @click="emitInput"
    >
        <input v-model="localValue" type="checkbox" :disabled="disabled" />
        <inline-svg :src="require('@/assets/img/checkbox-v2.svg')" />
        <slot />
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
    components: {
        InlineSvg,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        state: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {
            localValue: false,
        }
    },
    watch: {
        value() {
            this.localValue = this.value
        },
    },
    created() {
        this.localValue = this.value
    },
    methods: {
        emitInput(e) {
            if (!this.disabled && (!e || (e && e.target && e.target.tagName !== 'A'))) {
                this.localValue = !this.localValue
                this.$emit('input', this.localValue)
            }
        },
    },
}
</script>

<style lang="scss">
.wit-checkbox-v2 {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-family: 'Objectivity';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    margin-bottom: 14px;

    svg {
        display: inline-block;
        vertical-align: top;
        width: 20px;
        height: 20px;
        margin-right: 10px;

        rect {
            fill: none;
        }

        path {
            stroke: none;
        }
    }

    &--checked {
        svg {
            rect:first-child {
                fill: #ffc32b;
            }

            path {
                stroke: white;
            }

            rect:nth-of-type(2) {
                stroke: none;
            }
        }
    }

    a,
    a:hover {
        color: #eba900;
        text-decoration: none;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &--invalid {
        svg {
            rect:nth-of-type(2) {
                stroke: #fe482d;
            }
        }
    }
}
</style>
