<template>
    <form class="wit-tabless-form" @submit.prevent>
        <template v-for="input in inputs">
            <slot :name="`input-${input.name}`" :step="step">
                <wit-input-group
                    v-if="
                        input.hasOwnProperty('value') &&
                            ((input.hasOwnProperty('vIf') && input.vIf) || !input.hasOwnProperty('vIf'))
                    "
                    :key="input.name"
                    v-model="input.value"
                    :model="input.model"
                    :type="input.type"
                    :input-label="input.inputLabel"
                    :description="input.description"
                    :label="input.label"
                    :placeholder="input.placeholder"
                    :invalid="input.invalid"
                    :valid="input.valid"
                    :step="step"
                    :id="input.id ? input.id : `${formId}-${input.name}`"
                    :options="input.options"
                    :readonly="input.readonly"
                    :disabled="input.disabled || disabled"
                    :max="input.max"
                    :min="input.min"
                    :checked="input.checked"
                    :unchecked="input.unchecked"
                    :customLabel="input.customLabel"
                    :notReady="input.notReady"
                    :notReadyMessage="input.notReadyMessage"
                    :reduce="input.reduce"
                    :allowEmpty="input.allowEmpty"
                    :sub-label="input.subLabel"
                    :subtract-sub-label="input.subtractSubLabel"
                    :shopUrl="input.shopUrl"
                    @update:shopUrl="value => emitUpdate(value, input, 'shopUrl', input.onInput)"
                    :default-expand-level="input.defaultExpandLevel"
                    :before-clear-all="input.beforeClearAll"
                    :multiple="input.multiple"
                    :value-format="input.valueFormat"
                    :value-consists-of="input.valueConsistsOf"
                    :normalizer="input.normalizer"
                    :preselect-first="input.preselectFirst"
                    :scope="input.scope"
                    :requestedScopes="input.requestedScopes"
                    :input-col="12"
                    :show-reset="input.showReset"
                    :validation-messages="input.validationMessages"
                    :visible-hourly="input.visibleHourly"
                    :visible-daily="input.visibleDaily"
                    :visible-weekly="input.visibleWeekly"
                    :visible-monthly="input.visibleMonthly"
                    @input="value => emitInput(value, input, input.onInput)"
                >
                </wit-input-group>
            </slot>
        </template>
        <slot name="feedback" :step="step"></slot>
        <slot name="footer" :step="step">
            <b-row>
                <b-col>
                    <Feedback
                        :state="step.checked ? !step.invalid : null"
                        invalid="You need to finish the form before submitting"
                    ></Feedback>
                    <b-button
                        @click="finish"
                        :disabled="(step.checked && step.invalid) || disabled"
                        variant="primary"
                        :id="`${formId}-finish`"
                        class="wit-tabless-form__submit"
                        type="submit"
                    >
                        <span>{{ finishLabel }}</span>
                    </b-button>
                </b-col>
            </b-row>
        </slot>
    </form>
</template>

<script>
import VueNotifications from 'vue-notifications'
import Feedback from '@/components/Feedback'

export default {
    components: {
        Feedback,
        WitInputGroup: () => import('@/components/Inputs/WitInputGroup.vue'),
    },
    props: {
        formId: {
            type: String,
            required: true,
        },
        inputs: {
            type: Array,
            required: true,
        },
        details: {
            type: Boolean,
        },
        fetched: {
            type: Boolean,
        },
        valid: {
            type: Boolean,
        },
        dirty: {
            type: Boolean,
        },
        update: {
            type: Function,
        },
        updateRedirect: {
            type: String,
        },
        updateLabel: {
            type: String,
            default: 'Update',
        },
        finishLabel: {
            type: String,
            default: 'Finish',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    notifications: {
        updated: {
            type: VueNotifications.types.success,
            title: 'Updated successfully',
        },
        updatedError: {
            type: VueNotifications.types.error,
            title: 'Something went wrong',
        },
    },
    data() {
        return {
            localTabIndex: 0,
            step: {
                checked: false,
            },
        }
    },
    methods: {
        async finish() {
            this.step.checked = true

            if (this.valid) {
                if (this.details) {
                    if (!this.disabled) {
                        await this.updateClick({silent: true})
                    }

                    if (this.updateRedirect) {
                        this.$projectRouter.push(this.updateRedirect)
                    }
                } else {
                    if (!this.disabled) {
                        this.$emit('finish')
                    }
                }
            }
        },

        async updateClick(options) {
            try {
                await this.update()

                if (!options || (options && !options.silent)) {
                    this.updated()
                }
            } catch (e) {
                this.updatedError()
                this.$errorHandler.report(e, 'Could not update form')
            }
        },

        emitInput(value, input, onInput) {
            this.$emit('input', value, input, onInput)
        },

        emitUpdate(value, input, field, onInput) {
            this.$emit('update', value, input, field, onInput)
        },
    },
}
</script>

<style lang="scss">
.pad-buttons {
    padding-left: 2rem;
}

.wit-tabless-form {
    &__submit.btn {
        margin-top: 40px;
        display: block;
        padding: 16px;
        width: 100%;
        text-align: center;
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        background-color: #ffc32b;
        border: 0;
        text-shadow: none;

        &:hover:not(:disabled) {
            background-color: #000;
            color: #fff;
        }
    }
}
</style>
